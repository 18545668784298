var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": ['column', 'row'],
      "mx": "15px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": ['center', 'flex-start'],
      "min-w": ['250px']
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "14px",
      "color": "#333333",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.portion != null ? _c('c-text', {
    attrs: {
      "font-size": "14px",
      "color": "#555555"
    }
  }, [_vm._v(" " + _vm._s(_vm.portionDose) + " " + _vm._s(_vm.portionDoseUnit) + " ")]) : _vm._e()], 1), _c('c-button', {
    attrs: {
      "variant": "ghost",
      "h": "24px",
      "w": "24px",
      "min-h": "24px",
      "min-w": "24px",
      "m": "0",
      "p": "0",
      "d": ['auto', 'none'],
      "ml": "auto",
      "_focus": {
        outline: 'none',
        boxShadow: 'none'
      }
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = !_vm.isOpen;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "alt": "icon collapse",
      "h": "24px",
      "w": "24px",
      "transform": _vm.isOpen ? ['rotate(-90deg)'] : ['rotate(90deg)'],
      "animation": "2s transform ease"
    }
  })], 1)], 1), _c('c-collapse', {
    attrs: {
      "is-open": _vm.isOpen,
      "ml": [0, '20px'],
      "py": [0, '3px']
    }
  }, [_c('c-text', {
    attrs: {
      "mt": ['10px', 0],
      "font-size": "12px",
      "color": "#828282"
    }
  }, [_vm._v(" Pilihan golongan makanan ")]), _c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '10px'
      },
      expression: "{ gap: '10px' }"
    }],
    attrs: {
      "mt": "10px",
      "mx": "10px",
      "flex-wrap": "wrap",
      "justify-content": "flex-start"
    }
  }, _vm._l(_vm.meals, function (meal) {
    return _c('card-makanan-item', {
      key: meal.id,
      attrs: {
        "meal": meal
      },
      on: {
        "click": function click($event) {
          _vm.isPopupOpen = true;
        }
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }