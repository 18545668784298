var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', {
    attrs: {
      "variant": "ghost",
      "m": "0",
      "p": "0",
      "w": "72px",
      "h": "auto",
      "flex-dir": "column",
      "align-items": "center",
      "justify-content": "flex-start",
      "font-weight": "initial"
    },
    on: {
      "click": function click($event) {
        _vm.isPopupOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.photoURL,
      "alt": _vm.title,
      "h": "72px",
      "w": "72px",
      "object-fit": "cover",
      "box-shadow": "0px 0px 8px 5px rgba(0, 0, 0, 0.12)",
      "border-radius": "8px"
    }
  }), _c('c-text', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'word-wrap': 'anywhere'
      },
      expression: "{\n      'word-wrap': 'anywhere'\n    }"
    }],
    attrs: {
      "m": "0",
      "mt": "5px",
      "font-size": "12px",
      "text-align": "center",
      "color": "#333333",
      "max-w": "100%",
      "white-space": "break-spaces",
      "flex": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-text', {
    attrs: {
      "mt": "10px",
      "font-size": "12px",
      "text-align": "center",
      "color": "#888888"
    }
  }, [_vm._v(" " + _vm._s(_vm.dose) + " " + _vm._s(_vm.doseUnit) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }