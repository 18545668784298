<template>
  <c-flex
    :flex-dir="['column', 'row']"
    mx="15px"
  >
    <c-flex
      :align-items="['center', 'flex-start']"
      :min-w="['250px']"
    >
      <c-flex flex-dir="column">
        <c-text
          font-weight="500"
          font-size="14px"
          color="#333333"
          text-transform="capitalize"
        >
          {{ title }}
        </c-text>
        <c-text
          v-if="portion != null"
          font-size="14px"
          color="#555555"
        >
          {{ portionDose }} {{ portionDoseUnit }}
        </c-text>
      </c-flex>
      <c-button
        variant="ghost"
        h="24px"
        w="24px"
        min-h="24px"
        min-w="24px"
        m="0"
        p="0"
        :d="['auto', 'none']"
        ml="auto"
        :_focus="{
          outline: 'none',
          boxShadow: 'none',
        }"
        @click="isOpen = !isOpen"
      >
        <c-image
          :src="require('@/assets/icon-chevron-right.svg')"
          alt="icon collapse"
          h="24px"
          w="24px"
          :transform="isOpen ? ['rotate(-90deg)'] : ['rotate(90deg)']"
          animation="2s transform ease"
        />
      </c-button>
    </c-flex>
    <c-collapse
      :is-open="isOpen"
      :ml="[0, '20px']"
      :py="[0, '3px']"
    >
      <c-text
        :mt="['10px', 0]"
        font-size="12px"
        color="#828282"
      >
        Pilihan golongan makanan
      </c-text>
      <c-flex
        v-chakra="{ gap: '10px' }"
        mt="10px"
        mx="10px"
        flex-wrap="wrap"
        justify-content="flex-start"
      >
        <card-makanan-item
          v-for="meal in meals"
          :key="meal.id"
          :meal="meal"
          @click="isPopupOpen = true"
        />
      </c-flex>
    </c-collapse>
  </c-flex>
</template>

<script>
import CardMakananItem from './card-makanan-item.vue'

export default {
  name: 'CardMakanan',
  components: { CardMakananItem },
  props: {
    title: {
      type: String,
      required: true,
    },
    meals: {
      type: Array,
      required: true,
    },
    portion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
      isPopupOpen: false,
    }
  },
  computed: {
    portionDose() {
      return Number(this.portion?.dose).toFixed(1)
    },
    portionDoseUnit() {
      return this.portion.doseUnit
    },
  },
}
</script>
