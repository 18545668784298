<template>
  <c-button
    variant="ghost"
    m="0"
    p="0"
    w="72px"
    h="auto"
    flex-dir="column"
    align-items="center"
    font-weight="initial"
    @click="isPopupOpen = true"
  >
    <c-image
      :src="photoURL"
      :alt="meal.foodIngredient"
      h="72px"
      w="72px"
      object-fit="cover"
      box-shadow="0px 0px 8px 5px rgba(0, 0, 0, 0.12)"
      border-radius="8px"
    />
    <c-text
      m="0"
      mt="5px"
      font-size="12px"
      text-align="center"
      color="#333333"
    >
      {{ meal.foodIngredient }}
    </c-text>
    <c-text
      mt="0"
      font-size="12px"
      text-align="center"
      color="#888888"
    >
      {{ meal.dose }} {{ meal.doseUnit | satuanMakanan }}
    </c-text>

    <popup-container
      v-if="isPopupOpen"
      @close="isPopupOpen = false"
    >
      <template #title>
        <c-text
          font-weight="bold"
          font-size="18px"
          color="#111111"
        >
          {{ meal.foodIngredient }}
        </c-text>
      </template>
      <c-flex flex-dir="column">
        <c-flex
          v-chakra="{ gap: '10px' }"
          flex-wrap="wrap"
          justify-content="flex-start"
          px="10px"
        >
          <card-glossary-item
            v-for="(glossary, index) in meal.mealGlossary"
            :key="index"
            :title="glossary.ingredientName"
            :dose="glossary.dose"
            :dose-unit="glossary.doseUnit"
            :photo-url="glossary.photoUrl"
          />
        </c-flex>
        <c-button
          mt="30px"
          mx="10px"
          variant="outline"
          variant-color="primary"
          border-radius="full"
          as="router-link"
          :to="{name: 'glossaries'}"
        >
          Lihat glossary foto makanan
        </c-button>
      </c-flex>
    </popup-container>
  </c-button>
</template>

<script>
import PopupContainer from '@/components/modals/popup-container.vue'
import CardGlossaryItem from './card-glossary-item.vue'

export default {
  name: 'CardMakananItem',
  components: {
    PopupContainer, CardGlossaryItem,
  },
  props: {
    meal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isPopupOpen: false,
    }
  },
  computed: {
    photoURL() {
      let glossaryPhoto = this.meal.mealGlossary?.[0]?.photoUrl
      return this.meal.foodIngredientPhoto ?? glossaryPhoto ?? 'https://via.placeholder.com/72'
    },
  },
}
</script>
