var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "px": "15px",
      "mt": "15px",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-weight": "bold",
      "font-size": "16px",
      "color": "#111111",
      "is-truncated": true,
      "flex": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " "), _vm.menu.totalCalories !== 0 ? [_vm._v(" - " + _vm._s(_vm.totalCalories(_vm.menu)) + "kkal ")] : _vm._e()], 2), _vm.showTime ? _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-jam-grey.svg'),
      "alt": "icon waktu",
      "h": "18px",
      "w": "18px"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "6px",
      "font-size": "14px",
      "text-align": "right",
      "color": "#888888",
      "text-decoration": _vm.showTime ? '' : 'line-through 1px'
    }
  }, [_vm._v(" " + _vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime) + " ")])], 1) : _vm._e()], 1), _c('c-divider', {
    attrs: {
      "mx": "15px",
      "mb": "15px",
      "color": "primary.400",
      "border-bottom": "1px solid",
      "border-color": "primary.400"
    }
  }), _vm._l(_vm.meals, function (meal, index) {
    return _c('card-makanan', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: index,
      attrs: {
        "title": meal[0],
        "meals": meal[1],
        "portion": _vm.getPortionFor(meal[0]),
        "mt": index > 0 ? '35px' : 0
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }