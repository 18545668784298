var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', {
    attrs: {
      "variant": "ghost",
      "m": "0",
      "p": "0",
      "w": "72px",
      "h": "auto",
      "flex-dir": "column",
      "align-items": "center",
      "font-weight": "initial"
    },
    on: {
      "click": function click($event) {
        _vm.isPopupOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.photoURL,
      "alt": _vm.meal.foodIngredient,
      "h": "72px",
      "w": "72px",
      "object-fit": "cover",
      "box-shadow": "0px 0px 8px 5px rgba(0, 0, 0, 0.12)",
      "border-radius": "8px"
    }
  }), _c('c-text', {
    attrs: {
      "m": "0",
      "mt": "5px",
      "font-size": "12px",
      "text-align": "center",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.meal.foodIngredient) + " ")]), _c('c-text', {
    attrs: {
      "mt": "0",
      "font-size": "12px",
      "text-align": "center",
      "color": "#888888"
    }
  }, [_vm._v(" " + _vm._s(_vm.meal.dose) + " " + _vm._s(_vm._f("satuanMakanan")(_vm.meal.doseUnit)) + " ")]), _vm.isPopupOpen ? _c('popup-container', {
    on: {
      "close": function close($event) {
        _vm.isPopupOpen = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('c-text', {
          attrs: {
            "font-weight": "bold",
            "font-size": "18px",
            "color": "#111111"
          }
        }, [_vm._v(" " + _vm._s(_vm.meal.foodIngredient) + " ")])];
      },
      proxy: true
    }], null, false, 1946726699)
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        gap: '10px'
      },
      expression: "{ gap: '10px' }"
    }],
    attrs: {
      "flex-wrap": "wrap",
      "justify-content": "flex-start",
      "px": "10px"
    }
  }, _vm._l(_vm.meal.mealGlossary, function (glossary, index) {
    return _c('card-glossary-item', {
      key: index,
      attrs: {
        "title": glossary.ingredientName,
        "dose": glossary.dose,
        "dose-unit": glossary.doseUnit,
        "photo-url": glossary.photoUrl
      }
    });
  }), 1), _c('c-button', {
    attrs: {
      "mt": "30px",
      "mx": "10px",
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "full",
      "as": "router-link",
      "to": {
        name: 'glossaries'
      }
    }
  }, [_vm._v(" Lihat glossary foto makanan ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }